"use client";
import { signIn, useSession } from "next-auth/react";
import { redirect } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export default function Login() {
  const session = useSession();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("clicked_log_in");

    if (session.status === "authenticated") {
      redirect("/projects");
    } else {
      signIn("auth0", undefined, {
        prompt: "login",
        action_type: "login",
      });
    }
  }, [session.status]);

  return <></>;
}
